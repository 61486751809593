import { useState, useEffect } from "react";

import './PortfoliosModel_1.css';

import { SvgArrow, SvgSetaRight, SvgSeta_new } from "components/SvgFile";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Model_PortfoliosModel_1(props){

    const [ currentGallery, setCurrentGallery ] = useState(1);
    const [ dataPage, setDataPage ] = useState([
                {
                    "id": 1,
                    "title": "3D Product Videos",
                    "text": "Capture your audience's attention and ignite their imagination with our high-quality 3D product videos. Our team of animators and designers create engaging and dynamic videos that show your customers not just what your products look like, but how they work and feel in real-life scenarios. Let us help you create videos that will help you sell more products.",
                    "file": "./assets/examples/2.png"
                },
                {
                    "id": 2,
                    "title": "3D Product Visualization",
                    "text": "Capture your audience's attention and ignite their imagination with our high-quality 3D product videos. Our team of animators and designers create engaging and dynamic videos that show your customers not just what your products look like, but how they work and feel in real-life scenarios. Let us help you create videos that will help you sell more products.",
                    "file": "./assets/examples/3.png"
                },
                {
                    "id": 3,
                    "title": "3D Render for Ads",
                    "text": "Capture your audience's attention and ignite their imagination with our high-quality 3D product videos. Our team of animators and designers create engaging and dynamic videos that show your customers not just what your products look like, but how they work and feel in real-life scenarios. Let us help you create videos that will help you sell more products.",
                    "file": "./assets/examples/4.png"
                }

                ,
                {
                    "id": 4,
                    "title": "3D Product Videos",
                    "text": "Capture your audience's attention and ignite their imagination with our high-quality 3D product videos. Our team of animators and designers create engaging and dynamic videos that show your customers not just what your products look like, but how they work and feel in real-life scenarios. Let us help you create videos that will help you sell more products.",
                    "file": "./assets/examples/2.png"
                },
                {
                    "id": 5,
                    "title": "3D Product Visualization",
                    "text": "Capture your audience's attention and ignite their imagination with our high-quality 3D product videos. Our team of animators and designers create engaging and dynamic videos that show your customers not just what your products look like, but how they work and feel in real-life scenarios. Let us help you create videos that will help you sell more products.",
                    "file": "./assets/examples/3.png"
                },
                {
                    "id": 6,
                    "title": "3D Render for Ads",
                    "text": "Capture your audience's attention and ignite their imagination with our high-quality 3D product videos. Our team of animators and designers create engaging and dynamic videos that show your customers not just what your products look like, but how they work and feel in real-life scenarios. Let us help you create videos that will help you sell more products.",
                    "file": "./assets/examples/4.png"
                }
    ]);
    
    function AltPosition_prev(){
        var stream = document.querySelector('.div_gallery_model_1');
        var items  = document.querySelectorAll('.show_gallery_model_1');
        var prev   = document.querySelector('.gallery__prev_model_1');
        
        switch (currentGallery) {
            case 1:
                    setCurrentGallery(3);
                break;
            case 2:
                    setCurrentGallery(1);
                break;
            case 3:
                    setCurrentGallery(2);
                break;
        }
        stream.insertBefore(items[items.length - 1], items[0]);
        items = document.querySelectorAll('.show_gallery_model_1');
    }
    
    function AltPosition_next(){
        var stream = document.querySelector('.div_gallery_model_1');
        var items  = document.querySelectorAll('.show_gallery_model_1');
        var next   = document.querySelector('.gallery__next_model_1');
        
        switch (currentGallery) {
            case 1:
                    setCurrentGallery(2);
                break;
            case 2:
                    setCurrentGallery(3);
                break;
            case 3:
                    setCurrentGallery(1);
                break;
        }
        stream.appendChild(items[0]);
        items = document.querySelectorAll('.show_gallery_model_1');
    }

    return(
        <div className="Model_PortfoliosModel_1" style={ { backgroundColor: props.color } }>
            <div className="data_fixed">
                <div className="div_arrow">
                    <div className="gallery__prev_model_1 arrow_left" onClick={ ()=>{ AltPosition_prev(); } }>
                        <SvgArrow className="icons left" color="rgba(255, 255, 255, 0.3)" />
                        <div className="space_arrow_left" />
                    </div>
                    <div className="gallery__next_model_1 arrow_right" onClick={ ()=>{ AltPosition_next() } }>
                        <div className="space_arrow_right" />
                        <SvgArrow className="icons right" color="rgba(255, 255, 255, 0.3)" />
                    </div>
                </div>
                <div className="div_show_portfolio">
                    <div className="">
                        <SvgSetaRight className="icons" color="#ffffff" />
                    </div>
                    <div className="show_preview">
                        Discovery projects
                    </div>
                </div>
            </div>

            <div className="div_current_gallery">
                <div className="number">0{ currentGallery }</div>
                <div className="current_position">
                    <div className={ currentGallery == 1 ? "color_position position_active" : "color_position" } />
                    <div className={ currentGallery == 2 ? "color_position position_active" : "color_position" } />
                    <div className={ currentGallery == 3 ? "color_position position_active" : "color_position" } />
                </div>
                <div className="gallery_total">03</div>
            </div>

            <div className="div_gallery_model_1">
                {
                    dataPage.map((key, index)=>{
                        return(
                            <div className="show_gallery_model_1" key={ index }>
                                <div className="show_text">
                                    <div className="title">{ key.title }</div>
                                    <div className="text" dangerouslySetInnerHTML={ { __html: key.text ? key.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                </div>
                                <div className="background_img">
                                    <img alt="bg" src={ key.file } className="img_bg" />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}