import { useState, useEffect } from "react";

import './Footer.css';

import { SvgFacebook, SvgInstagram, SvgTwitter } from "components/SvgFile";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Model_Footer(props){

    const [ dataPage, setDataPage ] = useState([
        {
            "id": 1,
            "file": "./assets/examples/10.png"
        },
        {
            "id": 2,
            "file": "./assets/examples/10.png"
        }
    ]);
    const [ showData, setShowData ] = useState(InitialData());

    function InitialData(){
        const newData = dataPage.find(item => item.id == props.id);
        return newData;
    }

    return(
        <div className="Model_Footer" style={ { backgroundColor: props.color } }>
            
            <div className="div_color_line">
                <div className="line_top">
                    <div className="line_ft_1" />
                    <div className="line_ft_2" />
                    <div className="line_ft_3" />
                    <div className="line_ft_4" />
                </div>
                <div className="line_bottom">
                    <div className="line_ft_1" />
                    <div className="line_ft_2" />
                    <div className="line_ft_3" />
                    <div className="line_ft_4" />
                </div>
            </div>
            
            <div className="div_img">
                <a href="/#">
                    <img src={ showData.file } className="img" />
                </a>
            </div>

            <div className="div_data_footer contents">
                <div className="show_data">
                    <div className="div_btn">
                        <div className="title">Let Us Help You Captivate Your Audience</div>
                        <a href="/#" target="_blank" className="btn">
                            Get a Quote
                        </a>
                    </div>                    
                    <div className="div_name_page">
                        <div className="title_click">Services</div>
                        <div className="name_page">3D Product Videos</div>
                        <div className="name_page">3D Product Visualization</div>
                        <div className="name_page">3D Renders for Advertising</div>
                        <div className="name_page">Contact us</div>
                    </div>
                </div>

                <div className="div_data_rede_social">
                    <div className="origin">
                       Privacy Policy<br /> CNPJ 20.817.641/0001-40
                    </div>
                    <div className="origin">
                        Copyright ©ChromaGarden<br /> All rights reserved
                    </div>
                    <div className="show_rede_social">
                        <a href="#" className="rede_social">
                            <SvgInstagram className="icons" color="#9DAFBD" />
                        </a>
                        <a href="#" className="rede_social">
                            <SvgTwitter className="icons" color="#9DAFBD" />
                        </a>
                        <a href="#" className="rede_social">
                            <SvgFacebook className="icons" color="#9DAFBD" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}