import { useState, useEffect } from "react";

import './Menu.css';

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { SvgClose, SvgLogoTipo_2, SvgLogotipo, SvgMenuHamburguer } from "components/SvgFile";

export default function Menu(props){

    const [ page, setPage ]             = useState(GetListPag('page'));
    const [ idioma, setIdioma ]         = useState(GetListPag('idioma'));
    const [ nameIdioma, setNameIdioma ] = useState([
        {
            "href": "index",
            "name": {
                "en": "Home",
                "es": "Hogar",
                "pt_br": "Home"
            }
        },
        {
            "href": "about",
            "name": {
                "en": "About",
                "es": "Acerca de",
                "pt_br": "Quem somos"
            }
        },
        {
            "href": "works",
            "name": {
                "en": "Works",
                "es": "Obras",
                "pt_br": "Nosso trabalho"
            }
        },
        {
            "href": "products",
            "name": {
                "en": "Products",
                "es": "Productos",
                "pt_br": "Produtos"
            }
        }
    ]);

    const [ status, setStatus ] = useState(false);
    
    useEffect(()=>{
        RegisterListPag('page', setPage);
        RegisterListPag('idioma', setIdioma);
    }, []);

    function OpenMenu(){
        if(status == true){
            // document.getElementById('root').removeAttribute('style');
            if(document.querySelector('.bg_line_color')){
                document.querySelector('.bg_line_color').classList.remove('bg_top');
                document.querySelector('.bg_line_color').classList.add('bg_bottom');

                document.querySelector('.div_opt_menu').classList.remove('name_menu_show');  
                document.querySelector('.div_opt_menu').classList.add('name_menu_hide'); 
            }

            setTimeout(() => {
                if(document.querySelector('.line_color_1')){
                    document.querySelector('.line_color_1').classList.remove('animation_top');
                    document.querySelector('.line_color_1').classList.add('animation_bottom'); 
                }
            }, 300);
            setTimeout(() => {
                if(document.querySelector('.line_color_2')){
                    document.querySelector('.line_color_2').classList.remove('animation_top');
                    document.querySelector('.line_color_2').classList.add('animation_bottom'); 
                }
            }, 350);
            setTimeout(() => {
                if(document.querySelector('.line_color_3')){
                    document.querySelector('.line_color_3').classList.remove('animation_top');
                    document.querySelector('.line_color_3').classList.add('animation_bottom');
                }
            }, 400);
            setTimeout(() => {
                if(document.querySelector('.line_color_4')){
                    document.querySelector('.line_color_4').classList.remove('animation_top');
                    document.querySelector('.line_color_4').classList.add('animation_bottom');    
                    
                    document.querySelector('.list_rede_social').classList.remove('list_rede_social_animation_open');    
                    document.querySelector('.list_rede_social').classList.add('list_rede_social_animation_close');          
                }               
            }, 450);

            setTimeout(() => {
                document.querySelector('.bg_line_color').classList.remove('bg_bottom');
                document.querySelector('.div_opt_menu').classList.remove('name_menu_hide');    
                document.querySelector('.list_rede_social').classList.remove('list_rede_social_animation_close');  
            }, 1000);

            setStatus(false);
        }else {
            // document.getElementById('root').style.overflow = 'hidden';
            if(document.querySelector('.bg_line_color')){
                document.querySelector('.bg_line_color').classList.remove('bg_bottom');
                document.querySelector('.bg_line_color').classList.add('bg_top');
            }

            setTimeout(() => {
                if(document.querySelector('.line_color_1')){
                    document.querySelector('.line_color_1').classList.remove('animation_bottom');
                    document.querySelector('.line_color_1').classList.add('animation_top');
                }
            }, 300);
            setTimeout(() => {
                if(document.querySelector('.line_color_2')){
                    document.querySelector('.line_color_2').classList.remove('animation_bottom');
                    document.querySelector('.line_color_2').classList.add('animation_top');
                }
            }, 350);
            setTimeout(() => {
                if(document.querySelector('.line_color_3')){
                    document.querySelector('.line_color_3').classList.remove('animation_bottom');
                    document.querySelector('.line_color_3').classList.add('animation_top');
                }
            }, 400);
            setTimeout(() => {
                if(document.querySelector('.line_color_4')){
                    document.querySelector('.line_color_4').classList.remove('animation_bottom');
                    document.querySelector('.line_color_4').classList.add('animation_top'); 
                    
                    document.querySelector('.list_rede_social').classList.add('list_rede_social_animation_open'); 
                }
            }, 450);

            setTimeout(() => {
                document.querySelector('.div_opt_menu').classList.remove('name_menu_hide');  
                document.querySelector('.div_opt_menu').classList.add('name_menu_show'); 
            }, 600);

            setStatus(true);
        }
    }

    return(
        <div className="Menu">
            <div className="contents">
                <div className="div_logo">
                    <a href="./#index" onClick={ ()=>{ props.AltPag('index'); } }>
                        {
                            status == true ?
                            <SvgLogoTipo_2 className="logotipo" color="#2d3c53" /> :
                            <SvgLogoTipo_2 className="logotipo" color="#ffffff" />
                        }
                    </a>
                </div>

                <div className="div_line_menu" onClick={ ()=>{ OpenMenu(); } }>
                    <div className={ status == true ? "line_1_menu line_1_open" : "line_1_menu line_close" } />
                    <div className={ status == true ? "line_2_menu line_2_open" : "line_2_menu line_close" } />
                </div>

                <div className="bg_line_color">
                    <div className="line_color line_color_1">
                        <div className="data_address">
                            <div className="title">WE ARE ALWAYS HERE</div>
                            <div className="text_address">Av. lguaçu, 2947, 17º andar - Curitiba - Paraná - Brasil</div>
                            <div className="text_address">+55 (41) 99258-3829</div>
                        </div>
                    </div>
                    <div className="line_color line_color_2">
                        <div className="div_opt_menu">
                            {
                                nameIdioma.map((key, index)=>{
                                    return(
                                        <a href={ "/#" + key.href } className={ page == key.href ? "name_menu menu_active" : "name_menu" } key={ index } data-text={ key.name[idioma] } onClick={ ()=>{ props.AltPag(key.href); OpenMenu(); } }>{ key.name[idioma] }</a>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="line_color line_color_3" />
                    <div className="line_color line_color_4">
                        <div className="list_rede_social">
                            <a href="/#" className="rede_social">behance.</a>
                            <a href="/#" className="rede_social">twitter.</a>
                            <a href="/#" className="rede_social">dribble.</a>
                            <a href="/#" className="rede_social">instagram.</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}