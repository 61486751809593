import { useState, useEffect } from "react";

import './Clients.css';

import { SvgSetaDown, SvgSetaRight } from "components/SvgFile";

export default function Model_Clients(props){

    const [ dataPage, setDataPage ] = useState([
        {
            "file": "./assets/logo/brmalls_white-300x300.png",
            "name": "brmalls_white-300x300.png"
        },
        {
            "file": "./assets/logo/coca-cola_logo-300x300.png",
            "name": "coca-cola_logo-300x300.png"
        },
        {
            "file": "./assets/logo/copel_white-300x300.png",
            "name": "copel_white-300x300.png"
        },
        {
            "file": "./assets/logo/daf_white-300x300.png",
            "name": "daf_white-300x300.png"
        },
        {
            "file": "./assets/logo/ebanx_white-300x300.png",
            "name": "ebanx_white-300x300.png"
        },
        {
            "file": "./assets/logo/fih2_white-300x300.png",
            "name": "fih2_white-300x300.png"
        },
        {
            "file": "./assets/logo/gsk_white-300x300.png",
            "name": "gsk_white-300x300.png"
        },
        {
            "file": "./assets/logo/influx_logo-300x300.png",
            "name": "influx_logo-300x300.png"
        },
        {
            "file": "./assets/logo/john-deere_white-300x300.png",
            "name": "john-deere_white-300x300.png"
        },
        {
            "file": "./assets/logo/komatsu_logo-300x300.png",
            "name": "komatsu_logo-300x300.png"
        },
        {
            "file": "./assets/logo/laguna_logo-300x300.png",
            "name": "laguna_logo-300x300.png"
        },
        {
            "file": "./assets/logo/latecoere_logo-300x300.png",
            "name": "latecoere_logo-300x300.png"
        },
        {
            "file": "./assets/logo/luan_white-300x300.png",
            "name": "luan_white-300x300.png"
        },
        {
            "file": "./assets/logo/mackenzie_logo-300x300.png",
            "name": "mackenzie_logo-300x300.png"
        },
        {
            "file": "./assets/logo/neodent_logo-300x300.png",
            "name": "neodent_logo-300x300.png"
        },
        {
            "file": "./assets/logo/oboticario_white-300x300.png",
            "name": "oboticario_white-300x300.png"
        },
        {
            "file": "./assets/logo/sebrae_white-300x300.png",
            "name": "sebrae_white-300x300.png"
        },
        {
            "file": "./assets/logo/sicredi_white-300x300.png",
            "name": "sicredi_white-300x300.png"
        },
        {
            "file": "./assets/logo/volvo_white-300x300.png",
            "name": "volvo_white-300x300.png"
        },
        {
            "file": "./assets/logo/waaw_logo-300x300.png",
            "name": "waaw_logo-300x300.png"
        }
    ]);

    const [ list1, setList1 ] = useState(InitialData(0,4));
    const [ list2, setList2 ] = useState(InitialData(5,9));
    const [ list3, setList3 ] = useState(InitialData(10,14));
    const [ list4, setList4 ] = useState(InitialData(15,19));
    
    useEffect(()=>{
    }, []);

    function InitialData(numb_1, numb_2){
        const newData = [];
        dataPage.map((key, index)=>{
            if(index >= numb_1 && index <= numb_2){
                newData.push(key);
            }
        })
        return newData;
    }
    
    return(
        <div className="Model_Clients" style={ { backgroundColor: props.color } }>
            <div className="line_serv">
                {
                    list1.map((key, index)=>{
                        return(
                            <div className="div_logo_client" key={ index }>
                                <img alt={ key.name } src={ key.file } className="logo_client" />
                            </div>
                        )
                    })
                }
            </div>
            <div className="line_serv">
                {
                    list2.map((key, index)=>{
                        return(
                            <div className="div_logo_client" key={ index }>
                                <img alt={ key.name } src={ key.file } className="logo_client" />
                            </div>
                        )
                    })
                }
            </div>
            <div className="line_serv">
                {
                    list3.map((key, index)=>{
                        return(
                            <div className="div_logo_client" key={ index }>
                                <img alt={ key.name } src={ key.file } className="logo_client" />
                            </div>
                        )
                    })
                }
            </div>
            <div className="line_serv">
                {
                    list4.map((key, index)=>{
                        return(
                            <div className="div_logo_client" key={ index }>
                                <img alt={ key.name } src={ key.file } className="logo_client" />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}