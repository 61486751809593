import { useState, useEffect } from "react";

import './About.css';

import { GetListPag, RegisterListPag } from "interface/Page";

import Model_About from "components/Model/About";
import Model_Video from "components/Model/Video";
import Model_Clients from "components/Model/Clients";
import Model_OurServices from "components/Model/OurServices";
import Model_Footer from "components/Model/Footer";

export default function Page_About(props){

    const [ currentPage, setCurrentPage ] = useState(GetListPag('page'));
    
    useEffect(()=>{
        RegisterListPag('page', setCurrentPage);
    }, []);
    
    return(
        <>
            <Model_About color="#121212" position="1" id="1" />

            <Model_Video color="#121212" position="2" id="1" />

            <Model_Video color="#121212" position="2" id="2" />

            <Model_OurServices color="#121212" position="3" id="1" />

            <Model_About color="#121212" position="4" id="2" />

            <Model_Clients color="#121212" position="5" id="2" />

            <Model_Footer color="#121212" id="1" />
        </>
    )
}