import { useState, useEffect } from "react";

import './PortfoliosModel_2.css';

import { SvgArrow, SvgSetaRight, SvgSeta_new } from "components/SvgFile";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Model_PortfoliosModel_2(props){

    const [ currentGallery, setCurrentGallery ] = useState(1);
    const [ dataPage, setDataPage ] = useState([
                {
                    "id": 1,
                    "numb": 2,
                    "client": "Neodent",
                    "type": "3D Video Motion",
                    "year": "2023",
                    "title": "Increased Customer Engagement",
                    "text": "Our visually appealing and interactive content helps you to engage with your audience in a way that is both informative and entertaining. This can lead to stronger customer relationships and higher brand loyalty.",
                    "file": "./assets/examples/5.png"
                },
                {
                    "id": 2,
                    "numb": 3,
                    "client": "Chroma Garden",
                    "type": "3D Render for Ads",
                    "year": "2022",
                    "title": "Boosted Sales",
                    "text": "Our high-quality 3D visualizations and videos can help you to create visually appealing product displays that will draw in customers and create a memorable impression. This can help you to stand out in a crowded marketplace and attract more potential customers, ultimately boosting your sales.",
                    "file": "./assets/examples/6.png"
                },
                {
                    "id": 3,
                    "numb": 1,
                    "client": "GSK",
                    "type": "3D Render for Ads",
                    "year": "2021",
                    "title": "Savings and Efficiency",
                    "text": "Transition from traditional photography to our digital content creation and save money. 3D visualization gives you flexibility and control, so you can make changes without additional costs or delays.",
                    "file": "./assets/examples/7.png"
                }

                ,
                {
                    "id": 4,
                    "numb": 2,
                    "client": "Neodent",
                    "type": "3D Video Motion",
                    "year": "2023",
                    "title": "Increased Customer Engagement",
                    "text": "Our visually appealing and interactive content helps you to engage with your audience in a way that is both informative and entertaining. This can lead to stronger customer relationships and higher brand loyalty.",
                    "file": "./assets/examples/5.png"
                },
                {
                    "id": 5,
                    "numb": 3,
                    "client": "Chroma Garden",
                    "type": "3D Render for Ads",
                    "year": "2022",
                    "title": "Boosted Sales",
                    "text": "Our high-quality 3D visualizations and videos can help you to create visually appealing product displays that will draw in customers and create a memorable impression. This can help you to stand out in a crowded marketplace and attract more potential customers, ultimately boosting your sales.",
                    "file": "./assets/examples/6.png"
                },
                {
                    "id": 6,
                    "numb": 1,
                    "client": "GSK",
                    "type": "3D Render for Ads",
                    "year": "2021",
                    "title": "Savings and Efficiency",
                    "text": "Transition from traditional photography to our digital content creation and save money. 3D visualization gives you flexibility and control, so you can make changes without additional costs or delays.",
                    "file": "./assets/examples/7.png"
                }
    ]);
    
    function AltPosition_prev(){
        var stream = document.querySelector('.div_gallery_model_2');
        var items  = document.querySelectorAll('.show_gallery_model_2');
        var prev   = document.querySelector('.gallery__prev_model_2');
        
        switch (currentGallery) {
            case 1:
                    setCurrentGallery(3);
                break;
            case 2:
                    setCurrentGallery(1);
                break;
            case 3:
                    setCurrentGallery(2);
                break;
        }
        stream.insertBefore(items[items.length - 1], items[0]);
        items = document.querySelectorAll('.show_gallery_model_2');
    }
    
    function AltPosition_next(){
        var stream = document.querySelector('.div_gallery_model_2');
        var items  = document.querySelectorAll('.show_gallery_model_2');
        var next   = document.querySelector('.gallery__next_model_2');
        
        switch (currentGallery) {
            case 1:
                    setCurrentGallery(2);
                break;
            case 2:
                    setCurrentGallery(3);
                break;
            case 3:
                    setCurrentGallery(1);
                break;
        }
        stream.appendChild(items[0]);
        items = document.querySelectorAll('.show_gallery_model_2');
    }

    return(
        <div className="Model_PortfoliosModel_2" style={ { backgroundColor: props.color } }>
            <div className="data_fixed">
                <div className="div_show_preview">
                    <div className="div_name">
                        <div className="line" />
                        Enhanced Product Understanding
                    </div>
                </div>

                <div className="div_arrow">
                    <div className="gallery__prev_model_2 arrow_left" onClick={ ()=>{ AltPosition_prev() } }>
                        <SvgArrow className="icons left" color="#ffffff" />
                        <div className="space_arrow_left" />
                    </div>
                    <div className="gallery__next_model_2 arrow_right" onClick={ ()=>{ AltPosition_next() } }>
                        <div className="space_arrow_right" />
                        <SvgArrow className="icons right" color="#ffffff" />
                    </div>
                </div>
            </div>

            <div className="div_current_gallery">
                <div className="list_page">
                    <div className="number">01</div>
                    {
                        currentGallery == 1 ? <div className="position_active" /> : null
                    }
                </div>
                <div className="list_page">
                    <div className="number">02</div>
                    {
                        currentGallery == 2 ? <div className="position_active" /> : null
                    }
                </div>
                <div className="list_page">
                    <div className="number">03</div>
                    {
                        currentGallery == 3 ? <div className="position_active" /> : null
                    }
                </div>
            </div>
            
            <div className="div_gallery_model_2">
                {
                    dataPage.map((key, index)=>{
                        return(
                            <div className="show_gallery_model_2" key={ index }>
                                <div className="number_gallery">{ key.numb }</div>
                                <div className="show_text">
                                    <div className="data_client">
                                        <div className="">Client: { key.client }</div>
                                        <div className="">Type: { key.type }</div>
                                    </div>
                                    <div className="data_year">
                                        <span className="space_year">{ key.year }</span>
                                    </div>
                                    <div className="show_data_project">
                                        <div className="title">{ key.title }</div>
                                        <div className="text" dangerouslySetInnerHTML={ { __html: key.text ? key.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                    </div>
                                </div>
                                <div className="background_img">
                                    <img alt="bg" src={ key.file } className="img_bg" />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}