import { useState, useEffect } from "react";

import './OurServices.css';

import { SvgSetaDown, SvgSetaRight } from "components/SvgFile";

export default function Model_OurServices(props){

    const [ dataPage, setDataPage ] = useState([
        {
            "id": 1,
            "subtitle": "Our Services",
            "title": "What we create?",
            "text": "We work with technology to create interactive and immersive experiences that blends the physical and virtual worlds.",
            "list_btn": [
                {
                    "opt": 1,
                    "title": "Interactive Installations",
                    "text": "We work with technology to create interactive, immersive and innovative experiences for the world's biggest brands."
                },
                {
                    "opt": 2,
                    "title": "3D Digital Billboard",
                    "text": "3D billboard technology works on the principle of “forced perspective.” A technique that uses an object’s scale and the spectator’s vantage point to create an illusion of the object appearing larger, smaller, nearer, or distant from the viewer."
                },
                {
                    "opt": 3,
                    "title": "Video and Animation",
                    "text": "Combining technology and creativity, we help partners around the world tell their story in a whole new way."
                },
                {
                    "opt": 4,
                    "title": "Projection Mapping",
                    "text": "Bringing content to life with animation and creating impactful narratives over different types of surfaces, a building, a theater wall or even on an airplane."
                },
                {
                    "opt": 5,
                    "title": "Virtual Production",
                    "text": "We create interactive and immersive digital experiences that blend the physical and virtual worlds."
                }
            ]
        }
    ]);
    const [ showData, setShowData ] = useState(InitialData());

    const [ opt, setOpt ]         = useState(1);
    const [ listBtn, setListBtn ] = useState(showData.list_btn);
    
    useEffect(()=>{
    }, []);

    function InitialData(){
        const newData = dataPage.find(item => item.id == props.id);
        return newData;
    }

    function ShowBtn(value){
        setOpt(value);
    }

    return(
        <div className="Model_OurServices" style={ { backgroundColor: props.color } }>
            <div className={ props.position == 1 ? "contents space_top_" : "contents" }>
                <div className="div_text">
                    <div className="show_text">
                        <div className="subtitle">{ showData.subtitle }</div>
                        <div className="title" dangerouslySetInnerHTML={ { __html: showData.title ? showData.title.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                    </div>
                </div>

                <div className="show_list_btn">
                    <div className="list_btn">
                        <div className="text" dangerouslySetInnerHTML={ { __html: showData.text ? showData.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                        {
                            listBtn.map((key, index)=>{
                                return(
                                    <div className="div_btn" key={ index }>
                                        <div className="show_btn" onClick={ ()=>{ ShowBtn(key.opt) } }>
                                            <div className="name_btn">
                                                { key.title }
                                            </div>
                                            <div className="arrow_btn">
                                                {
                                                    key.opt == opt ?
                                                    <SvgSetaDown className="icons" color="#ffffff" /> :
                                                    <SvgSetaRight className="icons" color="#ffffff" />
                                                }
                                            </div>
                                        </div>
                                        <div className={ key.opt == opt ? "show_text text_open" : "show_text" } dangerouslySetInnerHTML={ { __html: key.opt == opt ? key.text ? key.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" : "" } } />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}