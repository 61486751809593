import { useState, useEffect } from "react";

import './Text.css';

import { SvgSetaRight, SvgSeta_new } from "components/SvgFile";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Model_Text(props){

    const [ dataPage, setDataPage ] = useState([
        {
            "id": 1,
            "subtitle": "",
            "title": "Your Vision,<br />Our Innovation",
            "text": "We believe every product has a story to tell. We help you tell that story in a way that engages and impacts your audience. From 3D product visualization and product video creation to 3D renders for advertising, we have the services you need to transform your products into visual masterpieces. Let us help you tell your product's story today.",
            "link": ""
        },
        {
            "id": 2,
            "subtitle": "",
            "title": "Enhanced Product Understanding",
            "text": "Our 3D visualizations and product videos allow your customers to fully understand your product's functionality and features. With a detailed, interactive view, they can see your product from every angle and in every environment. This helps them to make informed purchasing decisions.",
            "link": ""
        },
        {
            "id": 3,
            "subtitle": "about us",
            "title": "Visual Storytellers",
            "text": "Chroma Garden is a visual storytelling company that has been transforming product presentation for ten years with immersive 3D visualizations and dynamic product videos. Our experienced team combines technical expertise with creative flair to design digital content that engages, informs, and impresses. Let us help you turn your vision into a captivating digital reality.",
            "link": "#"
        }
    ])
    const [ showData, setShowData ] = useState(InitialData());

    function InitialData(){
        const newData = dataPage.find(item => item.id == props.id);
        return newData;
    }

    return(
        <div className="Model_Text" style={ { backgroundColor: props.color } }>
            <div className="contents">
                <div className="div_data_page">
                    <div className="div_title">
                        <div className="title" dangerouslySetInnerHTML={ { __html: showData.title ? showData.title.replaceAll('&#34;', '"') : "" } } />  
                    </div>
                    <div className="div_text">                    
                        {
                            showData.subtitle == "" ? null :
                            <div className="subtitle">{ showData.subtitle }</div>
                        }
                        <div className="text" dangerouslySetInnerHTML={ { __html: showData.text.replaceAll('&#34;', '"') } } />
                        {
                            showData.link == "" ? null :
                            <div className="div_link">
                                <a className="link" href={ showData.link }>
                                    <div className="line_link" />
                                    Click Here to know more
                                </a>
                            </div>
                        }
                    </div>
                </div>
                <div className="background_title">
                    <div className="title_fixed" dangerouslySetInnerHTML={ { __html: showData.title ? showData.title.replaceAll('&#34;', '"') : "" } } />
                </div>
            </div>
        </div>
    )
}