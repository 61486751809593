import { useState, useEffect } from "react";

import './Home.css';

import { SvgBehance, SvgInstagram_2, SvgYoutube } from "components/SvgFile";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Model_Home(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('site'));
    const [ showData, setShowData ] = useState(InitialData());

    const [ listSlide, setListSlide ] = useState(ShowDataList());

    const opts = { 
        loop: true,
        playerVars: {
            autoplay: true,
            controls: false,
            rel: false,
            showinfo: false,
            mute: true,
            loop: true
        } 
    };

    // m6yeR2xNu1A
    
    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('site', setDataPage);
    }, []);

    function InitialData(){
        // const newData = dataPage.find(item => item.id == props.id);
        // return newData;
    }

    function ShowDataList(){
        // if(showData[idioma].contents.length == 1){
        //     const newData = [...showData[idioma].contents];
        //     showData[idioma].contents.map((key, index)=>{
        //         newData.push(key);
        //         newData.push(key);
        //     })
        //     return newData
        // }else if(showData[idioma].contents.length == 2){
        //     const newData = [...showData[idioma].contents];
        //     showData[idioma].contents.map((key, index)=>{
        //         newData.push(key);
        //     })
        //     return newData
        // }else {
        //     return showData[idioma].contents;
        // }
    }
    
    function AltPosition(type){
        var stream = document.querySelector('.div_testimonials_carousel');
        var items = document.querySelectorAll('.testimonials_div_data');

        if(type == 'prev'){
            stream.insertBefore(items[items.length - 1], items[0]);
            items = document.querySelectorAll('.testimonials_div_data');
        }

        if(type == 'next'){
            stream.appendChild(items[0]);
            items = document.querySelectorAll('.testimonials_div_data');           
        }
    }
    
    function ShowVideo(type, video){
        switch (type) {
            case 'Vimeo':
                return(
                    <div className="video_background">
                        <div className="video_foreground">
                            <iframe src={ "https://player.vimeo.com/video/" + video + "?&amp;autoplay=1&amp;loop=1&amp;playlist=" + video + "&amp;rel=0&amp;controls=0&amp;mute=1" } frameBorder="0" allowFullScreen></iframe>
                        </div>
                    </div>
                )

            case 'Youtube':
                return(
                    <div className="video_background">
                        <div className="video_foreground">
                            <iframe src={ "https://www.youtube.com/embed/" + video + "?&amp;autoplay=1&amp;loop=1&amp;playlist=" + video + "&amp;rel=0&amp;controls=0&amp;mute=1" } frameBorder="0" allowFullScreen></iframe>
                        </div>
                    </div>
                )
        }
    }

    return(
        <div className="Model_Home" style={ { backgroundColor: props.color } }>
            <div className="div_show_data">  
            
                <div className="show_slide"> 
                    <div className="show_div_text">
                        <div className="div_text">
                            <div className="title">We Create<br />Experiences</div>
                        </div>
                        <div className="div_btn">
                            <div className="text">
                                Showcase your products in the most engaging and interactive way. Turn your ideas into reality with our high-quality 3D visualization and digital content creation services
                            </div>
                            {/* <a href="/#" target="_blank" className="btn">
                                Get a Quote
                            </a> */}
                        </div>
                    </div>
                    
                    <div className="dotted" />
                    <div className="show_rede_social">
                        <div className="list_data_rede_social">
                            <a href="/#contact" className="name_click" onClick={ ()=>{ } }>
                                let's talk
                            </a>
                            <div className="div_rede_social">
                                <div className="div_icons_social">
                                    <a href="https://www.youtube.com/user/chromagarden" target="_blank">
                                        <SvgYoutube className="icons_rede" color="#ffffff" />
                                    </a>
                                </div>
                                <div className="div_icons_social">
                                    <a href="https://www.instagram.com/chromagarden/" target="_blank">
                                        <SvgInstagram_2 className="icons_rede" color="#ffffff" />
                                    </a>
                                </div>
                                <div className="div_icons_social">
                                    <a href="https://www.behance.net/chromagarden" target="_blank">
                                        <SvgBehance className="icons_rede" color="#ffffff" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 
                    <div className="background_img">
                        <img alt="bg" src={ key.file } className="img_bg" />
                    </div> 
                    */}
                    { ShowVideo('Youtube', 'qrIdPQIYDbw') }
                </div>    
            </div>
        </div>
    )
}