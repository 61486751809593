import { useState, useEffect } from "react";

import './Home.css';

import { GetListPag, RegisterListPag } from "interface/Page";

import Model_Home from "components/Model/Home";
import Model_Text from "components/Model/Text";
import Model_Footer from "components/Model/Footer";
import Model_PortfoliosModel_1 from "components/Model/PortfoliosModel_1";
import Model_PortfoliosModel_2 from "components/Model/PortfoliosModel_2";
import Model_PortfoliosModel_3 from "components/Model/PortfoliosModel_3";

export default function Page_Home(props){

    const [ currentPage, setCurrentPage ] = useState(GetListPag('page'));
    
    useEffect(()=>{
        RegisterListPag('page', setCurrentPage);
    }, []);
    
    return(
        <>
            <Model_Home color="#121212" />

            <Model_Text color="#121212" id="1" />

            <Model_PortfoliosModel_1 color="#1d1d1d" />

            <Model_Text color="#121212" id="2" />

            <Model_PortfoliosModel_2 color="#1d1d1d" />

            <Model_Text color="#121212" id="3" />

            <Model_PortfoliosModel_3 color="#121212" />            

            <Model_Footer color="#1d1d1d" id="1" />
        </>
    )
}