import { useState, useEffect } from "react";

import './Video.css';

export default function Model_Video(props){

    const [ dataPage, setDataPage ] = useState([
        {
            "id": 1,
            "subtitle": "",
            "title": "",
            "text": "",
            "type_video": "Youtube",
            "video": "CpNinwn37gA"
        },
        {
            "id": 2,
            "subtitle": "CHROMA GARDEN",
            "title": "For the Age of Digital Content",
            "text": "",
            "type_video": "Vimeo",
            "video": "781118416?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1&h=7ae8c7d30d#t="
        }
    ]);
    const [ showData, setShowData ] = useState(InitialData());
    
    useEffect(()=>{
    }, []);

    function InitialData(){
        const newData = dataPage.find(item => item.id == props.id);
        return newData;
    }
    
    function ShowVideo(type, video){
        switch (type) {
            case 'Vimeo':
                return <iframe className="div_video" src={ "https://player.vimeo.com/video/" + video + "" } frameBorder="0" allow="fullscreen; picture-in-picture" />;

            case 'Drive':
                let linkDrive = video.split('/');
                return <iframe className="div_video" src={ "https://drive.google.com/file/d/" + linkDrive[5] + "/preview" } frameBorder="0" allow="fullscreen; picture-in-picture" />;    
            
            case 'Youtube':
                return <iframe className="div_video" src={ "https://www.youtube.com/embed/" + video + "" } frameBorder="0" allow="fullscreen; picture-in-picture" />;
        }
    }

    return(
        <div className="Model_Video" style={ { backgroundColor: props.color } }>
            <div className={ props.position == 1 ? "contents space_top_" : "contents" }>
                <div className="div_show_data">
                    {
                        showData.subtitle == '' ? null : 
                        <div className="subtitle">{ showData.subtitle }</div>
                    }
                    {
                        showData.title == '' ? null : 
                        <div className="title" dangerouslySetInnerHTML={ { __html: showData.title.replaceAll('&#34;', '"') } } />
                    }
                    <div className="show_video">
                        { ShowVideo(showData.type_video, showData.video) }
                    </div>
                </div>
            </div>
        </div>
    )
}