import { useState, useEffect } from "react";

import './Preview.css';

import { SetModalData, SetModalState } from "interface/PopUp";
import Model_Footer from "components/Model/Footer";

export default function Preview(props){
    
    const [ pageUrl, setPageUrl ]   = useState(window.location.href.split("#"));
    const [ dataPage, setDataPage ] = useState([
        {
            "id": 1,
            "title": "Waaw",
            "subtitle": "by Alok",
            "client": "WAP AND ALOK",
            "year": "2022",
            "type": "ANIMATION",
            "text": "Discover a unique experience in the way of listening and being heard. WAAW by ALOK offers a complete line with different models of headphones, earbuds and speakers with modern style and design. It transformed sound into movement and attitude.",
            "datasheet": "Waaw by Alok<br />Client: WAAW by ALOK<br />Executive Production: Chroma Garden<br /><br />Creative Direction: Lucas Souza<br /><br />Modeling: André Bales, André Luiz, Fernando Matias, Juscelio Dias, Lucas Souza, Lucas Tallman, Thiago Tallman and Wanderson Magalhães<br />LookDev: Gustavo Rodrigues, Lucas Souza, Renan Santa Terra and Thiago Tallman<br />Video Production: Lucas Souza<br />VFX: Alessandro Moreira, Lucas Souza, Renan Santa Terra and Thiago Tallman<br />Animation: Lucas Souza, Renan Santa Terra and Thiago Tallman<br /><br />Project Management: Thais Brito<br />Behance Design: Everton Lisboa<br /><br />Commercial Direction: Shawendy Ceschin<br />Commercial Assistant: Lucas Rocha<br />Strategic Assistant: Érica Romão<br /><br />Curitiba — Brazil",
            "file": "",
            "file": "./assets/portfolio/capa_waaw_tiny.png",
            "contents":[
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_02.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_03.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_04.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_05.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Vimeo",
                            "name": "video",
                            "file": "734489643?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1&h=893d412c88#t="
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Text",
                            "title": "ABOUT PROJECT",
                            "text": "We created a powerful visual language with a mix of 3D, VFX and animation, translating the sound waves into nature soundscapes. The products are characterized by a lively and detail oriented identity, communicated through colors, materials and details that enhance it. The combination of these elements defines a strong personality, to keep you connected to what really matters to you."
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Text",
                            "title": "PROJECT FEATURED",
                            "text": "BEHANCE | Featured in the 3D Products Visualization Gallery"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_06.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_07.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_08.jpg"
                        }
                    ]
                },
                {
                    "page_full": true,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_09.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_10.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_11.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_12.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_13.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_14.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Vimeo",
                            "name": "video",
                            "file": "735829688?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1&h=6b24d7c3e9#t="
                        }
                    ]
                },
                {
                    "page_full": true,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_15.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_16.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_17.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_18.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_19.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Vimeo",
                            "name": "video",
                            "file": "735829725?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1&h=9e79c19e34#t="
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_20.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_21.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_22.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Vimeo",
                            "name": "video",
                            "file": "735851293?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1&h=aa1848b775#t="
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_23.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_24.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_25.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Vimeo",
                            "name": "video",
                            "file": "735851262?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1&h=d18388f53f#t="
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_26.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_27.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_28.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_29.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_30.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_31.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_32.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_33.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_34.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_35.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_36.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_37.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_38.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_39.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_40.jpg"
                        }
                    ]
                },
                {
                    "page_full": true,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_41.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_42.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_43.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_44.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_45.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_46.jpg"
                        }
                    ]
                },
                {
                    "page_full": true,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_47.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_48.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_49.jpg"
                        }
                    ]
                },
                {
                    "page_full": true,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_50.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_51.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_52.jpg"
                        }
                    ]
                },
                {
                    "page_full": true,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_53.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_54.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_55.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_56.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_57.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_58.jpg"
                        }
                    ]
                },
                {
                    "page_full": true,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_59.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_60.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Vimeo",
                            "name": "video",
                            "file": "735837929?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1&h=f303ea04e3#t="
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_61.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_62.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_63.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_64.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Vimeo",
                            "name": "video",
                            "file": "735845309?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1#t="
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_65.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_66.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_67.jpg"
                        }
                    ]
                },
                {
                    "page_full": true,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_68.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_69.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Vimeo",
                            "name": "video",
                            "file": "735854856?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1#t="
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_71.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Image",
                            "name": "img_1",
                            "file": "./assets/portfolio/waaw_71.jpg"
                        },
                        {
                            "type": "Image",
                            "name": "img_2",
                            "file": "./assets/portfolio/waaw_72.jpg"
                        }
                    ]
                },
                {
                    "page_full": false,
                    "data": [
                        {
                            "type": "Vimeo",
                            "name": "video",
                            "file": "735853716?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1#t="
                        }
                    ]
                }
            ]
        }
    ]);
    const [ showData, setShowData ] = useState(InitialData());

    useEffect(()=>{
        document.title = showData.title + ' ' + showData.subtitle + ' - Chroma Garden';
    }, []);

    function InitialData(){
        const newData = dataPage.find(item => item.id == parseInt(pageUrl[2]));
        return newData;
    }
    
    function ShowVideo(type, video){
        switch (type) {
            case 'Vimeo':
                return <iframe className="div_video" src={ "https://player.vimeo.com/video/" + video + "" } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" />;

            case 'Drive':
                let linkDrive = video.split('/');
                return <iframe className="div_video" src={ "https://drive.google.com/file/d/" + linkDrive[5] + "/preview" } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" />;    
            
            default:
                return <iframe className="div_video" src={ "https://www.youtube.com/embed/" + video + "" } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" />;
        }
    }

    function OpenFile(value){
        SetModalData('ShowFile', { "file": value, "color": showData.bg_color });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="Preview">
            <div className="div_initial">
                <img alt="img" src={ showData.file } className="img_full" />
            </div>
            <div className="div_line">
                <div className="line bg_1" />
                <div className="line bg_2" />
                <div className="line bg_3" />
                <div className="line bg_4" />
            </div>

            <div className="div_data_project contents">
                <div className="div_data">
                    <div className="data_project">
                        <div className="title">{ showData.title }</div>
                        <div className="subtitle">{ showData.subtitle }</div>
                    </div>
                    <div className="data_project">
                        <div className="name_cliente">CLIENT: { showData.client }</div>
                        <div className="name_cliente">YEAR: { showData.year }</div>
                        <div className="name_cliente">TYPE: { showData.type }</div>
                    </div>
                </div>
                <div className="div_data align_top">
                    <div className="data_project text_project" dangerouslySetInnerHTML={ { __html: showData.text ? showData.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                </div>
            </div>

            {
                showData.contents.map((key, index)=>{
                    return(
                        <div className={ key.page_full == true ? "show_data_page page_full" : "show_data_page" } key={ index }>
                            {
                                key.data.map((key, index)=>{
                                    switch (key.type) {
                                        case 'Image':
                                            return(
                                                <div className="div_contents" key={ index }>
                                                    <img alt={ key.name } src={ key.file } className="show_img" />
                                                </div>
                                            )

                                        case 'Text':
                                            return(
                                                <div className="div_contents" key={ index }>
                                                    <div className="div_text">
                                                        <div className="title">{ key.title }</div>
                                                        <div className="text" dangerouslySetInnerHTML={ { __html: key.text ? key.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                    </div>
                                                </div>
                                            )

                                        case 'Vimeo': case 'Youtube':
                                            return(
                                                <div className="div_contents" key={ index }>
                                                    { ShowVideo(key.type, key.file) }
                                                </div>
                                            )
                                    }
                                })
                            }
                        </div>
                    )
                })
            }

            <Model_Footer id="1" />
        </div>
    )
}