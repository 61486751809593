import { useState, useEffect } from "react";

import './About.css';

export default function Model_About(props){

    const [ dataPage, setDataPage ] = useState([
        {
            "id": 1,
            "subtitle": "About the creative studio",
            "title": "We create experiences to connect brands and people",
            "text": "We work with agencies and companies from all industries, combining technology and creativity, we help brands to tell your story in a whole new way."
        },
        {
            "id": 2,
            "subtitle": "Our Business Partners",
            "title": "Video, Experience, Events",
            "text": "From ideation to execution, we collaborate with brands and agencies to bring great ideas to life."
        }
    ]);
    const [ showData, setShowData ] = useState(InitialData());
    
    useEffect(()=>{
    }, []);

    function InitialData(){
        const newData = dataPage.find(item => item.id == props.id);
        return newData;
    }

    return(
        <div className="Model_About" style={ { backgroundColor: props.color } }>
            <div className={ props.position == 1 ? "contents space_top_" : "contents" }>
                <div className="div_show_data">
                    <div className="subtitle">{ showData.subtitle }</div>
                    <div className="title" dangerouslySetInnerHTML={ { __html: showData.title.replaceAll('&#34;', '"') } } />
                    <div className="text">
                        <div className="show_text" dangerouslySetInnerHTML={ { __html: showData.text.replaceAll('&#34;', '"') } } />
                    </div>
                </div>
            </div>
        </div>
    )
}