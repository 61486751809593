import { cookiePage, cookiesRegister } from "fixedData";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

let ListPag = {
    "page"   : 'index',
    "idioma" : 'en'
};

let NotifyListPag = {
    "page"   : [],
    "idioma" : []
}

export function SetListPag(key, value){
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });

    if(key == 'page'){
        cookies.set(cookiePage, value, '/', cookiesRegister);
    }
}

export function GetListPag(key){
    return ListPag[key];
}

export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = [];
    }
    NotifyListPag[key].push(value);
}
