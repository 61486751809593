
let DataPage = {
    "show_idioma"  : {},
    "site"         : {}
};

let NotifyDataPage = {
    "show_idioma"  : [],
    "site"         : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["show_idioma"].forEach(element => {
        element(value['show_idioma']);
    });
    NotifyDataPage["site"].forEach(element => {
        element(value['site']);
    });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
