import { useState, useEffect } from "react";

import './PortfoliosModel_3.css';

import { SvgArrow, SvgSetaRight, SvgSeta_new } from "components/SvgFile";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Model_PortfoliosModel_3(props){

    const [ currentGallery, setCurrentGallery ] = useState(1);
    const [ dataPage, setDataPage ] = useState([
        {
            "title": "WHAT CLIENT SAYS:",
            "text": "We needed a way to showcase our products in a way that was both visually appealing and informative, and [company name]'s 3D renders were the perfect solution. Their renders were incredibly realistic",
            "file": "./assets/examples/8.png",
            "profile": "./assets/examples/profile_1.png",
            "name": "Emily Jørgensen",
            "office": "Author at Panoply Store"
        },
        {
            "title": "WHAT CLIENT SAYS:",
            "text": "We needed a way to showcase our products in a way that was both visually appealing and informative, and [company name]'s 3D renders were the perfect solution. Their renders were incredibly realistic",
            "file": "./assets/examples/9.png",
            "profile": "./assets/examples/profile_2.png",
            "name": "Alok Achkar Peres",
            "office": "Entrepreneur / DJ"
        }
        ,
        {
            "title": "WHAT CLIENT SAYS:",
            "text": "We needed a way to showcase our products in a way that was both visually appealing and informative, and [company name]'s 3D renders were the perfect solution. Their renders were incredibly realistic",
            "file": "./assets/examples/8.png",
            "profile": "./assets/examples/profile_1.png",
            "name": "Emily Jørgensen",
            "office": "Author at Panoply Store"
        },
        {
            "title": "WHAT CLIENT SAYS:",
            "text": "We needed a way to showcase our products in a way that was both visually appealing and informative, and [company name]'s 3D renders were the perfect solution. Their renders were incredibly realistic",
            "file": "./assets/examples/9.png",
            "profile": "./assets/examples/profile_2.png",
            "name": "Alok Achkar Peres",
            "office": "Entrepreneur / DJ"
        }
    ]);
    
    function AltPosition_prev(){
        var stream = document.querySelector('.div_gallery_model_3');
        var items  = document.querySelectorAll('.show_gallery_model_3');
        var prev   = document.querySelector('.gallery__prev_model_3');
        
        switch (currentGallery) {
            case 1:
                    setCurrentGallery(2);
                break;
            case 2:
                    setCurrentGallery(1);
                break;
        }
        stream.insertBefore(items[items.length - 1], items[0]);
        items = document.querySelectorAll('.show_gallery_model_3');
    }
    
    function AltPosition_next(){
        var stream = document.querySelector('.div_gallery_model_3');
        var items  = document.querySelectorAll('.show_gallery_model_3');
        var next   = document.querySelector('.gallery__next_model_3');
        
        switch (currentGallery) {
            case 1:
                    setCurrentGallery(2);
                break;
            case 2:
                    setCurrentGallery(1);
                break;
        }
        stream.appendChild(items[0]);
        items = document.querySelectorAll('.show_gallery_model_3');
    }

    return(
        <div className="Model_PortfoliosModel_3" style={ { backgroundColor: props.color } }>
            <div className="data_fixed">
                <div className="div_arrow">
                    <div className="gallery__prev_model_3 arrow_left" onClick={ ()=>{ AltPosition_prev() } }>
                        <SvgArrow className="icons left" color="#ffffff" />
                        <div className="space_arrow_left" />
                    </div>
                    <div className="gallery__next_model_3 arrow_right" onClick={ ()=>{ AltPosition_next() } }>
                        <div className="space_arrow_right" />
                        <SvgArrow className="icons right" color="#ffffff" />
                    </div>
                </div>
            </div>

            <div className="div_current_gallery">
                <div className="number">0{ currentGallery }</div>
                <div className="current_position">
                    <div className={ currentGallery == 1 ? "color_position position_active" : "color_position" } />
                    <div className={ currentGallery == 2 ? "color_position position_active" : "color_position" } />
                </div>
                <div className="gallery_total">02</div>
            </div>

            <div className="div_gallery_model_3">
                {
                    dataPage.map((key, index)=>{
                        return(
                            <div className="show_gallery_model_3" key={ index }>
                                <div className="data_img">
                                    <img alt="bg" src={ key.file } className="img_bg" />
                                </div>
                                <div className="data_text">
                                    <div className="show_text">
                                        <div className="title">{ key.title }</div>
                                        <div className="text" dangerouslySetInnerHTML={ { __html: key.text ? key.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                        <div className="div_profile">
                                            <div className="show_file_profile">
                                                <img alt="profile_" src={ key.profile } className="img_profile" />
                                            </div>
                                            <div className="show_data_profile">
                                                <div className="name_profile">{ key.name }</div>
                                                <div className="office_profile">{ key.office }</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}