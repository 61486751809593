// Connection type
const pageServidor = 'http://localhost/React_JS/dashboard_portfolio/v4_final/public';
// const pageServidor  = '.';
export const connectionPage = pageServidor;

// Cookies type
const cookiesServidor        = 'localhost';
// const cookiesServidor         = 'portfolio.chroma-garden.com/';
export const cookiesRegister  = cookiesServidor;

// Cookies name
export const cookiePage = 'chroma_internacional_page';
