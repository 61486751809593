import { useState, useEffect } from "react";

import { cookiePage } from "fixedData";

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import Cookies from 'universal-cookie';

import Menu from "components/Menu";
import LineFixed from "components/LineFixed";

import Page_Home from "components/Pages/Home";
import Page_About from "components/Pages/About";
import Preview from "components/Preview";

export default function App() {

    const cookies = new Cookies();

    const [ page, setPage ]       = useState(GetListPag('page'));
    const [ idioma, setIdioma ]   = useState(GetListPag('idioma'));
    const [ pageUrl, setPageUrl ] = useState(window.location.href.split("#"));
    
    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterListPag('page', setPage);
        CurrentPage(page);

        if(cookies.get(cookiePage)){
            let idPage = cookies.get(cookiePage);
            if(idPage == 'undefined'){
                SetListPag('page', 'index');
            }else {
                SetListPag('page', idPage);
            }
        }
    }, []);

    useEffect(()=>{
        CurrentPage(page);
        setPageUrl(window.location.href.split("#"));
    }, [page]);

    function ReturnTop(){
        window.scrollTo(0, 0);
    }    

    function AltPag(value){
        ReturnTop();
        setPage(value);
        CurrentPage(value);
        SetListPag('page', value);
    }

    function CurrentPage(){
        let namePage     = 'index';
        let showNamePage = window.location.href.split("#");
        if(showNamePage[1]){
            switch (showNamePage[1]) {
                case "index": case 'works': case 'about': case "products": case "preview":
                        namePage = showNamePage[1];
                    break;
                default:
                        window.history.pushState("", "", "/");
                        namePage = 'index';
                    break;
            }
        }else {
            namePage = page;
        }

        switch (namePage) {
            case "index":
                return <Page_Home AltPag={ AltPag } />;
            
            case "works":
                return '';
            
            case "about":
                return <Page_About AltPag={ AltPag } />;
            
            case "products":
                return "";

            case "preview":
                return <Preview AltPag={ AltPag } />
        
            default:
                return <Page_Home AltPag={ AltPag } />;
        }
    }

    return (
        <>
            <Menu AltPag={ AltPag } />
            <LineFixed />
            { CurrentPage() }
        </>
    );
}